import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { WHITE, LOGO_PRIMARY_LIGHT, LOGO_SECONDARY } from '../../util/theme';
import img from '../../assets/images/schedule_velacto.jpg';
import { Button } from '@material-ui/core';
import StringTable from '../../i18n/en-us/strings.json'; 
import { UnderDevelopment } from '../under-dev';

//******************************************************************************
const STRINGS = StringTable.products.RotationSchedulerDetails;
//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flex: '0 0 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    backgroundColor: LOGO_PRIMARY_LIGHT.css(),
    padding: [[40, 40, 80, 40]],
    scrollMarginTop: '60px',
  },
  description:{
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-end',
    position: 'relative',
    top: 50,
    right: 100,
    backgroundColor: WHITE.css(),
    width: '45%',
    zIndex: 10,
    padding: [[10, 10, 10, 20]],
  },
  descriptionTitle: {
    flex: '0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 24,
    cursor: 'default',
  },
  descriptionText: {
    flex: '1 0 auto',
    fontSize: 14,
    cursor: 'default',
  },
  img: {
    flex: '1 0 auto',
    position: 'relative',    
    top: -10,
    left: 100,
    width: '50%',
    zIndex: 5,
  },
}));
//------------------------------------------------------------------------------
export function RotationSchedulerDetails(props)
{
  const classes = useStyles(props);
  const [devOpen, setDevOpen] = useState(false);
  return(
    <div id={'scheduler'} className={clsx(props.className, classes.container)}>
      <img className={classes.img} src={img} alt={''}/>
      <div className={classes.description}>
        <div className={classes.descriptionTitle}>{STRINGS.title}</div>
        <p className={classes.descriptionText}>
          {STRINGS.contentL1}
          <br/>
          {STRINGS.contentL2}
          <br/>
          {STRINGS.contentL3}
          <br/>
          <br/>
          {STRINGS.contentL4}
          <br/>
          {STRINGS.contentL5}
          <br/>
          {STRINGS.contentL6}
          <br/>
          {STRINGS.contentL7}
          <br/>
          {STRINGS.contentL8}
          <br/>
          <br/>
          {STRINGS.contentL9}
          <br/>
          <br/>
          {STRINGS.contentL10}
          <br/>
          {STRINGS.contentL11}
          <br/>
        </p>
        <Button variant={'contained'} onClick={() => setDevOpen(true)}>
          {STRINGS.buttonName}
        </Button>
      </div>
      <UnderDevelopment open={devOpen} onClose={() => setDevOpen(false)}
        title={STRINGS.title}
        feature={'velacto-scheduler'}
        description={STRINGS.underDev}/>
    </div>
  );
}
//******************************************************************************
