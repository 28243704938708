import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ProductsTop } from '../components/products/products-top';
import { RotationSchedulerDetails } from '../components/products/scheduler-details';
import { ResourceManagerDetails } from '../components/products/resource-manager-details';
import { CustomersEngagement } from '../components/products/customers-engagement';
import { DocumentsManagement } from '../components/products/documents-management';

//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  item: {
    minHeight: '50vh',
    border: 'solid 1px blue',
  },
}));
//------------------------------------------------------------------------------
export function ProductsPage(props)
{
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <ProductsTop/>
      <ResourceManagerDetails/>
      <RotationSchedulerDetails/>
      <CustomersEngagement/>
      <DocumentsManagement/>
    </div>
  );
}
//------------------------------------------------------------------------------
export default ProductsPage;
//******************************************************************************